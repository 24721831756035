import ng from 'angular';
import acpCore from 'core';

interface IExtendedScope extends ng.IScope {
  callBack(): any;
}

function iframeOnload(): ng.IDirective {
  'ngInject';
  function link(scope: IExtendedScope, el: JQLite, attrs) {
    el.on('load', (event) => {
      scope.callBack()({ iframe: el[0] });
      scope.$apply();
    });
  }

  return {
    restrict: 'A',
    scope: {
      callBack: '&iframeOnload'
    },
    link
  };
}
const ngModule = ng
  .module('acp.shared.directives.iframeOnloadDirective', [acpCore.name])
  .directive('iframeOnload', iframeOnload);

export default ngModule;
